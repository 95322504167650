import { graphql } from "gatsby";
import Img from "gatsby-image";
import { RichText } from "prismic-reactjs";
import * as React from "react";
import styled from "styled-components";
import {
  Button,
  Column,
  CurvedSectionDivider,
  FormattedText,
  Gain,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  Line,
  MaxWidth,
  PageMaxWidth,
  Section,
  SectionQuotes,
  Text,
  Visibility,
} from "../components";
import { Color, Dimen } from "../constants";
import TICK_ORANGE from "../../img/templates/use_case/circle_checked.svg";
import { fixedImage, fluidImage } from "../prismic-tools";

export const pageQuery = graphql`
  query UseCase($uid: String!) {
    prismicUseCase(uid: { eq: $uid }) {
      uid
      data {
        hero_title
        hero_left_button
        hero_right_button
        hero_image {
          alt
          dimensions {
            width
            height
          }
          url
        }

        intro_title
        intro_description {
          intro_paragraph {
            raw
          }
        }

        quote_author_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
        quote_company_logo {
          alt
          dimensions {
            width
            height
          }
          url
        }
        quote_author_name
        quote_author_role
        quote_title
        quote_content {
          raw
        }

        teams_name
        teams {
          team_name
          team_icon
        }

        gains {
          gain_title
          gain_pre_title
          gain_description {
            raw
          }
          gain_image {
            alt
            dimensions {
              width
              height
            }
            url
          }
        }

        workflow_title

        workflow_steps {
          workflow_step_title
          workflow_step_description
        }

        support_types_title
        support_types_description {
          raw
        }

        support_types {
          support_type
        }

        support_what_title
        support_what_description {
          raw
        }

        support_whats {
          support_what
        }

        support_scenarios_title

        support_scenarios {
          support_scenario
        }

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.UseCaseQuery;
};
export default function UseCasePage({ data }: Props) {
  const useCase = data.prismicUseCase?.data;
  if (!useCase) {
    return null;
  }

  const teamColor: { [key: string]: keyof typeof Color } = {
    box: "yellow1",
    finance: "green2",
    people: "lightBlue1",
    import_ops: "secondaryPurple1",
    customs_agents: "violet1",
  };

  return (
    <Layout>
      <HelmetSeo {...useCase} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <Grid alignColumns="center" reverseColumns>
            <Column size={6}>
              <MaxWidth width={528}>
                <Img fluid={fluidImage(useCase.hero_image, 528)} />
              </MaxWidth>
            </Column>
            <Column size={1} />
            <Column size={5}>
              <Text typeface="h1" as="h1" bold>
                {useCase.hero_title}
              </Text>
              <Gutter size={2} />
              <div style={{ display: "flex" }}>
                <Button
                  colorTheme="orange"
                  label={useCase.hero_left_button}
                  to="/contact"
                />
                <Gutter />
                <Button
                  colorTheme="hollow-blue"
                  label={useCase.hero_right_button}
                  to="/contact"
                />
              </div>
            </Column>
          </Grid>
          <Gutter size={3} />

          <Text typeface="h3" bold as="h2">
            {useCase.intro_title}
          </Text>
          <Gutter size={1.5} />
          <Grid>
            {useCase.intro_description?.map(
              (desc, i) =>
                desc && (
                  <React.Fragment key={i}>
                    <Column size={5}>
                      <FormattedText>
                        <RichText render={desc.intro_paragraph?.raw} />
                      </FormattedText>
                    </Column>
                    <Column size={1} />
                  </React.Fragment>
                )
            )}
          </Grid>
          <Gutter size={3} />
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        frontColor="newDark2"
        position="top"
        backColor="light1"
      />
      <SectionQuotes quotes={[useCase]} />
      <Section background="dark">
        <PageMaxWidth>
          <Gutter size={4} />
          <Line color="orange1" />
          <Gutter size={2.5} />
          <MaxWidth width={700}>
            <HorizontalAlign align="center">
              <Text color="light1" bold typeface="h3" as="h2" align="center">
                {useCase.teams_name}
              </Text>
            </HorizontalAlign>
            <Gutter />
          </MaxWidth>
          <Gutter size={1.5} />
          <Grid justifyColumns="center">
            {useCase.teams?.map(
              (team, i) =>
                team && (
                  <Column size={4} key={team.team_name}>
                    <StyledTeam $last={i === (useCase.teams?.length || 0) - 1}>
                      <Text
                        typeface="h5"
                        bold
                        as="p"
                        color={
                          teamColor[
                            team.team_icon as keyof typeof teamColor
                          ] as keyof typeof Color
                        }
                      >
                        {team.team_name}
                      </Text>
                      <Gutter md={0.5} />
                      <img
                        src={require(`../../img/templates/use_case/team_${team.team_icon}.svg`)}
                        alt=""
                      />
                    </StyledTeam>
                  </Column>
                )
            )}
          </Grid>
          <Gutter />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        frontColor="newDark2"
        position="bottom"
        backColor="light1"
      />
      <Section>
        <PageMaxWidth>
          <Gutter size={6} md={2.5} />
          {useCase.gains?.map(
            (gain, i) =>
              gain && (
                <>
                  <Gain
                    reverseColumns={i % 2 === 0}
                    preTitle={gain.gain_pre_title}
                    preTitleColor={
                      (
                        ["blue1", "violet1", "green1"] as Array<
                          keyof typeof Color
                        >
                      )[i % 3]
                    }
                    title={gain.gain_title}
                    content={gain.gain_description}
                    image={gain.gain_image}
                  />
                  <Gutter size={6} md={2.5} />
                </>
              )
          )}
        </PageMaxWidth>
      </Section>
      <CurvedSectionDivider
        frontColor="newDark2"
        position="top"
        backColor="light1"
      />
      <Section background="dark">
        <PageMaxWidth>
          <Gutter />
          <MaxWidth width={700}>
            <HorizontalAlign align="center">
              <Text color="light1" bold typeface="h3" as="h2" align="center">
                {useCase.workflow_title}
              </Text>
            </HorizontalAlign>
            <Gutter />
          </MaxWidth>
          <Gutter size={3} />
          <StyledWorkflowSteps>
            {useCase.workflow_steps?.map(
              (step, i) =>
                step && (
                  <StyledWorkflowStep
                    key={step.workflow_step_title}
                    $highlight={i === 3}
                  >
                    <div className="left">
                      <Text bold typeface="h4" color="light1" as="h3">
                        Step {i + 1}
                      </Text>
                      <Gutter size={2} md={0.25} />
                      <Visibility hideBelow="md">
                        <img
                          src={require(`../../img/templates/use_case/step${
                            i + 1
                          }.svg`)}
                          height={80}
                          alt=""
                        />
                        <Gutter />
                      </Visibility>
                      <Text color="yellow1" typeface="caption" bold as="h3">
                        {step.workflow_step_title}
                      </Text>
                      <Gutter md={0.5} />
                      <Text color="light1" typeface="caption">
                        {step.workflow_step_description}
                      </Text>
                    </div>
                    <div className="right">
                      <img
                        src={require(`../../img/templates/use_case/step${
                          i + 1
                        }.svg`)}
                        height={80}
                      />
                    </div>
                  </StyledWorkflowStep>
                )
            )}
          </StyledWorkflowSteps>
          <Gutter size={2} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDivider
        frontColor="newDark2"
        position="bottom"
        backColor="light1"
      />
      <Section>
        <PageMaxWidth>
          <Gutter size={5} />
          <Grid justifyColumns="center">
            {(useCase.support_types?.length || 0) > 0 && (
              <>
                <Column size={5}>
                  <Text typeface="h3" bold as="h2">
                    {useCase.support_types_title}
                  </Text>
                  <Gutter size={2} />
                  <FormattedText>
                    <RichText render={useCase.support_types_description?.raw} />
                  </FormattedText>
                  <Gutter />
                  <StyledTickPoints>
                    {useCase.support_types?.map(
                      (item, i) =>
                        item && (
                          <div key={i}>
                            <img src={TICK_ORANGE} alt="" />
                            <Gutter size={0.5} />
                            <Text>{item.support_type}</Text>
                          </div>
                        )
                    )}
                  </StyledTickPoints>
                </Column>
                <Column size={1} />
              </>
            )}

            <Column size={6}>
              <StyledCard>
                <Text typeface="h4" bold>
                  {useCase.support_what_title}
                </Text>
                <Gutter />
                <StyledTickPoints $columns>
                  {useCase.support_whats?.map(
                    (item, i) =>
                      item && (
                        <div key={i}>
                          <img src={TICK_ORANGE} alt="" />
                          <Gutter size={0.5} />
                          <Text>{item.support_what}</Text>
                        </div>
                      )
                  )}
                </StyledTickPoints>
              </StyledCard>
            </Column>
          </Grid>
          <Gutter size={4} md={2} />
          {(useCase.support_scenarios?.length || 0) > 0 && (
            <>
              <Text typeface="h4" bold as="h2">
                {useCase.support_scenarios_title}
              </Text>
              <Gutter />
              <StyledTickPoints>
                {useCase.support_scenarios?.map(
                  (item, i) =>
                    item && (
                      <div key={i}>
                        <img src={TICK_ORANGE} alt="" />
                        <Gutter size={0.5} />
                        <Text>{item.support_scenario}</Text>
                      </div>
                    )
                )}
              </StyledTickPoints>
              <Gutter size={4} md={2} />
            </>
          )}
        </PageMaxWidth>
      </Section>
    </Layout>
  );
}

const StyledTeam = styled("div")<{ $last: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${(p) =>
    !p.$last &&
    `
    &::after {
      content: '';
      width: 1px;
      height: 130px;
      background-color: #7c7c7c;
      position: absolute;
      top: 64px;
      right: -${Dimen.gutterWidth / 2}px; 
    }
  `}

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    flex-direction: row-reverse;
    max-width: 202px;
    margin: 0 auto;
    padding-bottom: ${Dimen.gutterWidth}px;

    p {
      flex: 1;
    }

    img {
      width: 84px;
    }

    &::after {
      top: auto;
      left: 0;
      right: 0;
      bottom: 0;
      width: auto;
      height: 1px;
    }
  }
`;

const StyledCard = styled.div`
  box-shadow: 0px 12px 56px rgba(19, 19, 31, 0.05);
  border-radius: 9px;
  padding: 20px;
`;

const StyledWorkflowSteps = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

const StyledWorkflowStep = styled("div")<{ $highlight: boolean }>`
  width: 20%;
  background-color: ${Color.newDark2};
  ${(p) =>
    p.$highlight &&
    `
  border: 1px dashed ${Color.light1};
  box-shadow: 0px 6px 17px #000000;
`};
  display: flex;
  align-items: center;

  .left {
    flex: 1;
    padding: ${Dimen.gutterWidth}px ${Dimen.gutterWidth / 2}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .right {
    flex: 1;
    display: none;
    justify-content: center;
  }

  @media (max-width: ${Dimen.breakpointDownMd}px) {
    width: 100%;
    margin: ${Dimen.gutterWidth / 4}px 0;

    .left {
      padding: ${Dimen.gutterWidth / 2}px ${Dimen.gutterWidth / 2}px;
      align-items: flex-start;
      text-align: left;
    }

    .right {
      display: flex;
    }
  }
`;

const StyledTickPoints = styled("div")<{ $columns?: boolean }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > div {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 10px ${Dimen.gutterWidth / 2}px 10px 0;

    img {
      margin-top: 3px;
    }
  }

  ${(p) =>
    p.$columns &&
    `
    > div {
      width: 50%;
    }

    @media (max-width: ${Dimen.breakpointDownSm}px) {
      > div {
        width: 100%;
      }
    }
  `};
`;
